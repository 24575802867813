import React, { useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "../layout/layout";
import SEO from "../components/seo";

import Banner from "../components/banner";
import CompetitionDetail from "../components/competitionDetail";
import Header from "../components/header";
import TextBlock from "../components/textblock";
import Image from "../components/image";
import Video from "../components/video";
import UnorderedList from "../components/unorderedlist";

const CompetitionTemplate = ({ data }) => {
    const sections = data.sanityCompetition.sections;
    useEffect(() => {
        if (data.sanityCompetition) {
            document.body.classList.add("competition");
        }
    });
    return (
        <>
            <Layout>
                <SEO
                    canonical={data.sanityCompetition.competition_url.current}
                    title={data.sanityCompetition.competition_name}
                />
                <Banner
                    imageData={data.sanityCompetition.competition_image.asset.gatsbyImageData}
                    alt={data.sanityCompetition.competition_name}
                />
                <main className="container w-full sm:w-5/6 md:w-2/3 mx-auto flex flex-wrap -mt-12 sm:-mt-36 p-6">
                    <CompetitionDetail
                        name={data.sanityCompetition.competition_name}
                        entry={data.sanityCompetition.entry_date}
                        age={data.sanityCompetition.age_group}
                        desc={data.sanityCompetition.short_desc}
                    />
                    {sections.map((section) => {
                        const typeName = section.__typename;

                        switch (typeName) {
                            case "SanityHeader":
                                return <Header key={section._key} {...section} />;
                            case "SanityTextBlock":
                                return <TextBlock key={section._key} {...section} />;
                            case "SanityImageBlock":
                                return <Image key={section._key} {...section} />;
                            case "SanityVideo":
                                return <Video key={section._key} {...section} />;
                            case "SanityUnorderedList":
                                return <UnorderedList key={section._key} {...section} />;
                            default:
                                return <p>Nothing to see here</p>;
                        }
                    })}
                </main>
            </Layout>
        </>
    );
};

export const query = graphql`
    query ($slug: String) {
        sanityCompetition(competition_url: { current: { eq: $slug } }) {
            competition_image {
                asset {
                    gatsbyImageData
                }
            }
            competition_name
            competition_url {
                current
            }
            entry_date
            formlink
            id
            short_desc
            age_group
            sections {
                __typename
                ...ImageBlock
                ...TextBlockSection
                ... on SanityVideo {
                    _key
                    _type
                    videoTitle
                    videoUrl
                }
                ...Header
                ...UnorderedListSection
            }
        }
    }
`;

export default CompetitionTemplate;
