import React from "react";

const CompetitionDetail = (props) => {
    return (
        <div class="competition-details">
            <h2>{props.name}</h2>
            <div className="flex flex-wrap competition-info">
                <span>Entry date: {props.entry}</span>
                <span>Ages: {props.age}</span>
            </div>
            <p>{props.desc}</p>
        </div>
    );
};

export default CompetitionDetail;
